import { AsgardeoSPAClient, HttpRequestConfig } from '@asgardeo/auth-react';
import axios, { AxiosRequestConfig } from 'axios';
import logger from 'utils/logger';
import * as Sentry from '@sentry/browser';

import { apiBaseUrl } from 'config';
import {
  TOKEN_REFRESH_ERROR_EVENT,
  ASGARDEO_SDK_REFRESH_TOKEN_ERROR_CODE,
} from 'data/constants';
import { emitter } from './emitter';

export default (config: AxiosRequestConfig = {}) => axios(config);

// asgardeo
const auth = AsgardeoSPAClient.getInstance();

const httpRequest = (config: HttpRequestConfig) => {
  if (process.env.REACT_APP_TRACE_HTTP_REQUESTS === 'true') {
    logger.trace('Trace HTTP Request: ', config);
  }
  return auth?.httpRequest(config).catch((error: any) => {
    // Breadcrumb for x-correlation-id
    const correlationId = (config?.headers ?? {})['x-correlation-id'];
    if (correlationId) {
      Sentry.addBreadcrumb({
        category: 'x-correlation-id',
        level: Sentry.Severity.Info,
        data: {
          url: config.url,
          correlationId,
        },
      });
    }
    if (error?.code === ASGARDEO_SDK_REFRESH_TOKEN_ERROR_CODE) {
      emitter.emit(TOKEN_REFRESH_ERROR_EVENT, {});
    }
    throw error;
  });
};

const getConfig = (config: any): HttpRequestConfig => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  ...config,
});

export const request = (config: HttpRequestConfig = {}) =>
  auth?.httpRequest(getConfig(config));

export const get = (
  path: string,
  params?: any,
  overrideBaseUrl = false,
  headers?: any,
  responseType?: string
) => {
  const url: string = overrideBaseUrl ? path : `${apiBaseUrl}/${path}`;
  return httpRequest(
    getConfig({ method: 'GET', headers, responseType, url, params })
  );
};

export const post = (
  path: string,
  params?: any,
  data?: any,
  overrideBaseUrl = false,
  shouldEncodeToFormData?: boolean,
  headers?: any,
  responseType?: string
) => {
  const url: string = overrideBaseUrl ? path : `${apiBaseUrl}/${path}`;
  return httpRequest(
    getConfig({
      method: 'POST',
      url,
      params,
      headers,
      data,
      shouldEncodeToFormData,
      responseType,
    })
  );
};

export const put = (
  path: string,
  params: any,
  data: any,
  overrideBaseUrl = false,
  shouldEncodeToFormData = false,
  headers?: any
) => {
  const url: string = overrideBaseUrl ? path : `${apiBaseUrl}/${path}`;
  return httpRequest(
    getConfig({
      method: 'PUT',
      url,
      params,
      headers,
      data,
      shouldEncodeToFormData,
    })
  );
};

export const remove = (path: string, params?: {}, overrideBaseUrl = false) => {
  const url: string = overrideBaseUrl ? path : `${apiBaseUrl}/${path}`;
  return httpRequest(getConfig({ method: 'DELETE', url, params }));
};

export const patch = (
  path: string,
  data: any,
  overrideBaseUrl = false,
  params: any = {}
) => {
  const url: string = overrideBaseUrl ? path : `${apiBaseUrl}/${path}`;
  return httpRequest(getConfig({ method: 'PATCH', url, params, data }));
};

//  named as deleteHttpRequest as `delete` keyword is not allowed in strict mode
export const deleteHttpRequest = (
  path: string,
  params: any,
  data?: any,
  overrideBaseUrl = false
) => {
  const url: string = overrideBaseUrl ? path : `${apiBaseUrl}/${path}`;
  return httpRequest(getConfig({ method: 'DELETE', url, params, data }));
};
