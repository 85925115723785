import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { azureInsightsKey } from 'config';
import { AsgardeoSPAClient } from '@asgardeo/auth-react';

import { EnvironmentTypes } from 'types/environment';
import { ComponentDisplayType } from 'types';
import logger from './logger';
import { isStrictlyNecessaryCookiesAllowed } from './oneTrustCookiePro';

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: azureInsightsKey,
    extensions: [reactPlugin],
  },
});
if (azureInsightsKey) {
  ai.loadAppInsights();
}

export const trackEvent = async (name: string, customProperties?: {}) => {
  // Strictly necessary cookie(s) are not allowed
  const isAppInsightAllowed = isStrictlyNecessaryCookiesAllowed();
  if (!isAppInsightAllowed) {
    return;
  }
  const { appInsights } = ai;
  if (azureInsightsKey) {
    const properties: { [key: string]: string } = {
      ...customProperties,
      context: 'console',
    };

    const auth = AsgardeoSPAClient.getInstance();
    if (auth) {
      try {
        const basicUserInfo = await auth.getBasicUserInfo();
        if (basicUserInfo?.sub) {
          properties.idpId = basicUserInfo.sub;
        }

        const userEmail = basicUserInfo?.email;
        if (userEmail) {
          const isWSO2User = !!userEmail.endsWith('@wso2.com');
          properties.isWSO2User = `${isWSO2User}`;
        }
      } catch (error) {
        logger.error('Failed to get auth details for azure event', error);
      }
    }

    appInsights.trackEvent({ name }, properties);
  }
};

const convertNameToKebabCase = (str?: string) => {
  const matchStr =
    str &&
    str.match(
      /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
    );

  return matchStr && matchStr.map((x) => x.toLowerCase()).join('-');
};

export const trackChoreoLogoClick = () => {
  trackEvent('navbar-home');
};

export const trackRecentComponentItemClick = () => {
  trackEvent('home-recents-component');
};

export const trackComponentCreateStart = (
  orgUuid: string,
  location: 'nav-bar' | 'project-grid' | 'component-list' | 'mono-repo',
  projectId?: string
) => {
  trackEvent('component-create-start', {
    org: orgUuid,
    project: projectId,
    location,
  });
};

export const trackComponentCreateIntermediate = (
  orgUuid: string,
  step:
    | 'select-type'
    | 'select-buildpack'
    | 'select-mono-repo-component'
    | 'save-mono-repo-config',

  stepInput?: string
) => {
  trackEvent('component-create-intermediate', {
    org: orgUuid,
    step,
    stepInput,
  });
};

export const trackComponentCreateEnd = (
  projectId: string,
  location: 'mono-repo-import' | 'component-form',
  componentType: string,
  buildpack: string,
  orgUuid: string
) => {
  trackEvent('component-create-end', {
    project: projectId,
    location,
    componentType,
    buildpack,
    org: orgUuid,
  });
};

export const trackProjectCreateStart = (
  orgUuid: string,
  location: 'nav-bar' | 'project-grid'
) => {
  trackEvent('project-create-start', { org: orgUuid, location });
};

export const trackProjectCreateIntermediate = (
  orgUuid: string,
  step: 'enter-basic-details' | 'select-git-provider' | 'select-git-repo',
  stepInput?: string
) => {
  trackEvent('project-create-intermediate', {
    org: orgUuid,
    step,
    stepInput,
  });
};

export const trackProjectCreateEnd = (
  projectId: string,
  orgUuid: string,
  region: string
) => {
  trackEvent('project-create-end', {
    project: projectId,
    projectRegion: region,
    org: orgUuid,
  });
};

export const trackTriggerBuild = (
  componentId: string,
  projectId: string,
  orgUuid: string
) => {
  trackEvent('component-build', {
    component: componentId,
    project: projectId,
    org: orgUuid,
  });
};

export const trackTriggerAutoDeploy = (
  componentId: string,
  projectId: string,
  orgUuid: string,
  autoDeploy: boolean
) => {
  trackEvent('component-build-intermediate', {
    component: componentId,
    project: projectId,
    autoDeploy: autoDeploy ? 'true' : 'false',
    org: orgUuid,
  });
};

export const trackTriggerDeploy = (
  componentId: string,
  projectId: string,
  orgUuid: string
) => {
  trackEvent('component-deploy', {
    component: componentId,
    project: projectId,
    org: orgUuid,
  });
};

export const trackTriggerDeployIntermediate = (
  componentId: string,
  projectId: string,
  orgUuid: string,
  location: string
) => {
  trackEvent('component-deploy-intermediate', {
    component: componentId,
    project: projectId,
    org: orgUuid,
    location,
  });
};

export const trackTriggerPromote = (
  componentId: string,
  projectId: string,
  orgUuid: string,
  envFrom: string,
  envTo: string
) => {
  trackEvent('component-promote', {
    component: componentId,
    project: projectId,
    org: orgUuid,
    envFrom,
    envTo,
  });
};

export const trackVisitHome = (orgUuid: string) => {
  trackEvent('visit-home', {
    org: orgUuid,
  });
};

export const trackJoinDemoOrg = () => {
  trackEvent('join-demo-org');
};

export const trackSelectExistingProject = (projectName?: string) => {
  trackEvent('navbar-project-dropdown-existing', { project: projectName });
};

export const trackBuildAreaDeploy = (componentId?: string, orgId?: string) => {
  trackEvent('component-deploy-build-area-deploy', {
    component: componentId,
    org: orgId,
  });
};

export const trackConfigureClick = (
  componentId?: string,
  envName?: string,
  orgId?: string
) => {
  if (envName === EnvironmentTypes.ProdEnv) {
    trackEvent('component-deploy-dev-configure', {
      component: componentId,
      org: orgId,
    });
  } else if (envName === EnvironmentTypes.DevEnv) {
    trackEvent('component-deploy-build-configure', {
      component: componentId,
      org: orgId,
    });
  }
};

export const trackConfigurableSaveClick = (
  componentId?: string,
  envName?: string,
  orgId?: string
) => {
  if (envName === EnvironmentTypes.ProdEnv) {
    trackEvent('component-deploy-dev-promote', {
      component: componentId,
      org: orgId,
    });
  } else if (envName === EnvironmentTypes.DevEnv) {
    trackEvent('component-deploy-build-area-deploy', {
      component: componentId,
      org: orgId,
    });
  }
};

export const trackBuildViewLogsClick = (
  componentId?: string,
  orgId?: string
) => {
  trackEvent('component-deploy-build-area-view-logs', {
    component: componentId,
    org: orgId,
  });
};

export const trackComponentOverviewNavClick = (
  componentId?: string,
  orgId?: string
) => {
  trackEvent('component-overview', {
    component: componentId,
    org: orgId,
  });
};

export const trackDevelopNavClick = (componentId?: string, orgId?: string) => {
  trackEvent('component-develop', {
    component: componentId,
    org: orgId,
  });
};

export const trackExecutionsNavClick = (
  componentId?: string,
  orgId?: string
) => {
  trackEvent('component-executions', {
    component: componentId,
    org: orgId,
  });
};

export const trackTestNavClick = (
  location: string,
  componentId?: string,
  projectID?: string,
  orgUuid?: string
) => {
  trackEvent('component-test', {
    location,
    component: componentId,
    project: projectID,
    org: orgUuid,
  });
};

export const trackManageNavClick = (componentId?: string, orgId?: string) => {
  trackEvent('component-manage', {
    component: componentId,
    org: orgId,
  });
};

export const trackSaveApiKey = (componentId?: string, orgId?: string) => {
  trackEvent('component-test-postman-add', {
    component: componentId,
    org: orgId,
  });
};

export const trackTestApiExecuteClick = (
  componentId?: string,
  orgId?: string
) => {
  trackEvent('component-test-execute', {
    component: componentId,
    org: orgId,
  });
};

export const trackOpenApiGetTestKeyClick = (
  componentId?: string,
  orgId?: string
) => {
  trackEvent('component-test-openapi-get-test-key', {
    component: componentId,
    org: orgId,
  });
};

export const trackSidebarHomeClick = () => {
  trackEvent('sidebar-home');
};

export const trackSidebarInsightsClick = () => {
  trackEvent('sidebar-insights');
};

export const trackSidebarComponentsClick = () => {
  trackEvent('sidebar-components');
};

export const trackSidebarDataplanesClick = () => {
  trackEvent('sidebar-dataplanes');
};

export const trackSidebarEnvironmentsClick = () => {
  trackEvent('sidebar-environments');
};

export const trackSidebarHelmMarketplaceClick = () => {
  trackEvent('sidebar-helm-marketplace');
};

export const trackComponentPageLoad = (
  projectId?: string,
  componentId?: string,
  newComponent?: boolean
) => {
  trackEvent('component-page-load', {
    project: projectId,
    component: componentId,
    newComponent: `${newComponent}`,
  });
};

export const trackComponentTabClose = (
  projectId?: string,
  componentId?: string
) => {
  trackEvent('component-tab-close', {
    project: projectId,
    component: componentId,
  });
};

export const trackOwnRepoViewPrLink = (projectId?: string) => {
  trackEvent('component-view-pr-with-own-repo', {
    project: projectId,
  });
};

export const trackOptionalPathSelectClick = (
  projectId: string,
  userManagedNonEmpty: boolean
) => {
  trackEvent('component-select-show-optional-with-own-repo', {
    project: projectId,
    createWithOwnRepo: userManagedNonEmpty ? 'exiting' : 'empty',
  });
};

export const trackUserSignUpSuccess = (queryParams?: [string, string][]) => {
  if (queryParams && queryParams.length > 0) {
    const additionalParams: { [key: string]: string } = {};
    queryParams.forEach(([name, value]) => {
      additionalParams[name] = value;
    });
    trackEvent('signup-success', additionalParams);
  } else {
    trackEvent('signup-success');
  }
};

export const trackUserLoginSuccess = (
  returnUrl?: string,
  isSilent?: boolean
) => {
  trackEvent('login-clickbutton-success', {
    url: returnUrl,
    silentLogin: isSilent,
  });
};

export const trackUserLoginError = (returnUrl?: string, isSilent?: boolean) => {
  trackEvent('login-clickbutton-error', {
    url: returnUrl,
    silentLogin: isSilent,
  });
};

export enum OpenWithVSCodeTriggerPath {
  HOME = 'home',
  COMPONENTS = 'components',
}

export const trackOpenProjectWithVSCodeClick = (
  triggerPath: OpenWithVSCodeTriggerPath,
  projectId: string
) => {
  if (triggerPath === OpenWithVSCodeTriggerPath.HOME) {
    trackEvent('home-project-open-with-vscode', {
      project: projectId,
    });
  } else if (triggerPath === OpenWithVSCodeTriggerPath.COMPONENTS) {
    trackEvent('components-project-open-with-vscode', {
      project: projectId,
    });
  }
};

export const trackGetVSCodeClick = (
  triggerPath: OpenWithVSCodeTriggerPath,
  projectId: string
) => {
  if (triggerPath === OpenWithVSCodeTriggerPath.HOME) {
    trackEvent('home-project-get-vscode', {
      project: projectId,
    });
  } else if (triggerPath === OpenWithVSCodeTriggerPath.COMPONENTS) {
    trackEvent('components-project-get-vscode', {
      project: projectId,
    });
  }
};

export const trackGetChoreoExtensionClick = (
  triggerPath: OpenWithVSCodeTriggerPath,
  projectId: string
) => {
  if (triggerPath === OpenWithVSCodeTriggerPath.HOME) {
    trackEvent('home-project-get-choreo-extension', {
      project: projectId,
    });
  } else if (triggerPath === OpenWithVSCodeTriggerPath.COMPONENTS) {
    trackEvent('components-project-get-choreo-extension', {
      project: projectId,
    });
  }
};

export const trackDocumentationNavClick = () => {
  trackEvent('navbar-documentation');
};

export const trackBillingNavClick = () => {
  trackEvent('navbar-user-billing');
};

export const trackLogoutNavClick = () => {
  trackEvent('navbar-user-logout');
};

export const trackSampleGetStartedClick = (projectId?: string) => {
  trackEvent('components-new-create-sample-getstarted', {
    project: projectId,
  });
};

export const trackSampleComponentTypeClick = (componentType?: string) => {
  let sectionName = 'home-existing-project';
  if (window?.location?.pathname?.endsWith('components/new')) {
    sectionName = 'components-new';
  }

  let componentTypeStr = componentType;
  switch (componentType) {
    case ComponentDisplayType.RestApi:
      componentTypeStr = 'httpApi';
      break;
    case ComponentDisplayType.Proxy:
    case ComponentDisplayType.GitProxy:
      componentTypeStr = 'httpProxyApi';
      break;
    default:
      break;
  }
  trackEvent(
    `${sectionName}-click-create-component-${convertNameToKebabCase(
      componentTypeStr
    )}`
  );
};

export const trackSampleViewAllClick = (projectId?: string) => {
  trackEvent('home-existing-project-view-samples', {
    project: projectId,
  });
};

export const trackMarketplaceTabClick = (tabName: string) => {
  trackEvent(`marketplace-${tabName}-click`);
};

export const trackManageOverviewClick = (componentId?: string) => {
  trackEvent('component-manage-overview', {
    component: componentId,
  });
};

export const trackManageLifeCycleClick = (componentId?: string) => {
  trackEvent('component-manage-lifecycle', {
    component: componentId,
  });
};

export const trackManageConsumersClick = (componentId?: string) => {
  trackEvent('component-manage-consumers', {
    component: componentId,
  });
};

export const trackManageDocumentsClick = (componentId?: string) => {
  trackEvent('component-manage-documents', {
    component: componentId,
  });
};

export const trackManageUsagePlansClick = (componentId?: string) => {
  trackEvent('component-manage-usage-plans', {
    component: componentId,
  });
};

export const trackManageSecuritySave = (componentId?: string) => {
  trackEvent('component-manage-settings-security-save', {
    component: componentId,
  });
};

export const trackManageResourcesSave = (componentId?: string) => {
  trackEvent('component-manage-settings-resources-save ', {
    component: componentId,
  });
};

export const trackManageSettingsClick = (componentId?: string) => {
  trackEvent('component-manage-settings', {
    component: componentId,
  });
};

export const trackManagePropertiesClick = (componentId?: string) => {
  trackEvent('component-manage-properties', {
    component: componentId,
  });
};

export const trackManageScopesClick = (componentId?: string) => {
  trackEvent('component-manage-scopes', {
    component: componentId,
  });
};

export const trackApimAnalyticsPortalEvents = (
  eventName: string,
  customProperties = {}
) => {
  trackEvent(eventName, customProperties);
};

export const trackAPIAnalyticsNavigationFromDoc = () => {
  trackEvent('api-analytics-refer-documentation');
};

export const trackOnPremKeyTabNavigation = () => {
  trackEvent('api-analytics-on-prem-keys');
};

export const trackEnvironmentNameUserInput = () => {
  trackEvent('api-analytics-add-env');
};

export const trackOnPremKeyGeneration = () => {
  trackEvent('api-analytics-generate-key');
};

export const trackOnPremKeyRegeneration = () => {
  trackEvent('api-analytics-regenerate-key');
};

export const trackOnPremKeyCopyEvent = () => {
  trackEvent('api-analytics-copy-key');
};

export const trackWSO2ContactLinkClickEvent = () => {
  trackEvent('api-analytics-contact-wso2');
};

export const trackMarketplaceItemClick = (properties: {
  type?: string;
  organization?: string;
  name?: string;
  version?: string;
  searchText?: string | null;
  searchCategory?: string | null;
  searchFilterBy?: string | null;
  searchPublishedBy?: string | null;
  sortValue?: string | null;
}) => {
  const customProperties: any = {
    type: properties.type,
    organization: properties.organization,
    name: properties.name,
    version: properties.version,
  };
  if (properties.searchText) {
    customProperties.querySearch = properties.searchText;
  }
  if (properties.sortValue) {
    customProperties.querySort = properties.sortValue;
  }
  if (properties.searchCategory) {
    customProperties.queryCategory = properties?.searchCategory;
    const [mainCategory, subCategory] = properties?.searchCategory.split('/');
    customProperties.mainCategory = mainCategory;
    if (subCategory) {
      customProperties.subCategory = subCategory;
    }
  }
  if (properties.searchPublishedBy) {
    customProperties.queryPublishedBy = properties.searchPublishedBy;
  }
  if (properties.searchFilterBy) {
    customProperties.queryFilterBy = properties.searchFilterBy;
  }

  trackEvent('marketplace-item-click', customProperties);
};

export const trackOnMarketplaceItemLoad = (properties: {
  searchText?: string | null;
  selectedCategory?: string;
  filterByValues?: string[];
  sortValue?: string | null;
  publishedOrg?: string | null;
  type?: string;
  resultsCount?: number;
}) => {
  const {
    searchText,
    selectedCategory,
    filterByValues,
    sortValue,
    publishedOrg,
    type,
    resultsCount,
  } = properties;
  if (
    searchText ||
    selectedCategory ||
    filterByValues?.length ||
    sortValue ||
    publishedOrg
  ) {
    const customProperties: any = {};
    customProperties.type = type;
    customProperties.resultsCount = resultsCount ? `${resultsCount}` : '0';
    if (publishedOrg) {
      customProperties.queryPublishedBy = publishedOrg;
    }
    if (sortValue) {
      customProperties.querySort = sortValue;
    }
    if (searchText) {
      customProperties.querySearch = searchText;
    }

    if (selectedCategory) {
      customProperties.queryCategory = selectedCategory;
      const [mainCategory, subCategory] = selectedCategory.split('/');
      customProperties.mainCategory = mainCategory;
      if (subCategory) {
        customProperties.subCategory = subCategory;
      }
    }
    if (filterByValues?.length) {
      customProperties.queryFilterBy = filterByValues.join(',');
    }

    trackEvent('marketplace-item-list-load', customProperties);
  }
};

export const trackMarketplaceDocumentationClick = (properties: {
  type?: string;
  organization?: string;
  name?: string;
  version?: string;
}) => {
  trackEvent('marketplace-item-view-api-documentation-click', properties);
};

export const trackMarketplaceUseThisClick = (properties: {
  type?: string;
  organization?: string;
  name?: string;
  version?: string;
}) => {
  trackEvent('marketplace-item-view-use-this-click', properties);
};

export const trackApiLifeCycleStateChange = (
  apiNewState?: string,
  projectId?: string,
  componentId?: string
) => {
  const newComponentStr = `component-manage-lifecycle-state-change-to-${convertNameToKebabCase(
    apiNewState
  )}`;
  trackEvent(newComponentStr, {
    project: projectId,
    component: componentId,
  });
};

export const trackPublishAddToMarketplace = (
  visibility?: string,
  projectId?: string,
  componentId?: string
) => {
  trackEvent(`component-manage-publish-to-marketplace`, {
    project: projectId,
    component: componentId,
    visibility,
  });
};

export const trackViewInMarketplace = (
  projectId?: string,
  componentId?: string
) => {
  trackEvent(`component-manage-view-in-marketplace`, {
    project: projectId,
    component: componentId,
  });
};

export const trackDevPortalNavClick = () => {
  trackEvent('navbar-dev-portal');
};

export const trackLifeCycleDevPortalNavClick = () => {
  trackEvent('component-manage-dev-portal');
};

export const trackObserverSampleConfirmClick = (
  componentId?: string,
  projectId?: string
) => {
  trackEvent('component-observe-sample-confirm-click', {
    component: componentId,
    project: projectId,
  });
};

export const trackObserveSubNavClick = (
  sectionName: string,
  componentId?: string,
  projectId?: string,
  isSample?: boolean
) => {
  trackEvent(`component-observe-${sectionName?.toLowerCase()}-tab-click`, {
    component: componentId,
    project: projectId,
    isSample: `${isSample}`,
  });
};

export const trackObserveThroughputClick = (
  componentId?: string,
  projectId?: string,
  isSample?: boolean
) => {
  trackEvent(`component-observe-throughput-graph-click`, {
    component: componentId,
    project: projectId,
    isSample: `${isSample}`,
  });
};

export const trackComponentDeletion = (
  componentId?: string,
  projectId?: string
) => {
  trackEvent('component-delete', {
    component: componentId,
    project: projectId,
  });
};

export const trackMoesifAPIKeySave = (
  appId: string,
  orgUuid: string,
  envType: string
) => {
  trackEvent('moesif-api-key-save', {
    applicationId: appId,
    environmentType: envType,
    org: orgUuid,
  });
};

export const trackVisitMoesifDashboard = (orgUuid: string) => {
  trackEvent('visit-moesif-dashboard', {
    org: orgUuid,
  });
};

export const trackDBCreateStartClick = (orgUuid: string) => {
  trackEvent('db-create-btn-click', {
    org: orgUuid,
  });
};

export const trackDBCreateIntermediate = (orgUuid: string, dbType: string) => {
  trackEvent('db-create-intermediate', {
    org: orgUuid,
    databaseType: dbType,
  });
};

export const trackDBCreateEnd = (
  orgUuid: string,
  cloudProvider: string,
  region: string,
  servicePlan: string
) => {
  trackEvent('db-create-end', {
    org: orgUuid,
    cloudProvider,
    region,
    servicePlan,
  });
};

export const trackVisitDatabaseCreationPage = (orgUuid: string) => {
  trackEvent('visit-db-creation-page', {
    org: orgUuid,
  });
};

export const trackMessageBrokerCreatePage = (orgUuid: string) => {
  trackEvent('visit-message-broker-creation-page', {
    org: orgUuid,
  });
};

export const trackMessageBrokerCreateStartClick = (orgUuid: string) => {
  trackEvent('mb-create-btn-click', {
    org: orgUuid,
  });
};

export const trackQuickDeployClick = (
  orgUuid: string,
  projectId: string,
  sampleName: string
) => {
  trackEvent('quick-deploy-click', {
    org: orgUuid,
    project: projectId,
    sample: sampleName,
  });
};

export const trackQuickDeployBannerTryButtonClick = (
  projectId: string,
  componentHandle: string,
  componentType: ComponentDisplayType
) => {
  trackEvent('quick-deploy-banner-try-button-click', {
    project: projectId,
    componentName: componentHandle,
    componentType,
  });
};
