import React from 'react';
import './styles.scss';

interface PageLoaderContainerProps {
  children: JSX.Element;
}
const PageLoaderContainer = (props: PageLoaderContainerProps) => {
  const { children } = props;
  return <div className="pageLoaderContainer">{children}</div>;
};
export default PageLoaderContainer;
