import React from 'react';
import { PreLoaderLogo } from '../PreLoaderLogo';
import './styles.scss';

function LoadingLogo() {
  return (
    <div className="loadingLogo">
      <PreLoaderLogo size="medium" />
    </div>
  );
}

export default LoadingLogo;
