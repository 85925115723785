export interface StopDeployment {
  stopDeployment: string;
}

export interface ReDeployment {
  redeployDeployment: string;
}

export enum DeploymentStatus {
  NotDeployed = 'NOT_DEPLOYED',
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
}

export enum DeploymentStepStatus {
  InProgress = 'in_progress',
  Completed = 'completed',
  Skipped = 'skipped',
  Failure = 'failure',
  Queued = 'queued',
}

export enum BuildStepStatus {
  InProgress = 'in-progress',
  Completed = 'completed',
  Skipped = 'skipped',
  Failure = 'failure',
  Failed = 'failed',
  Queued = 'queued',
  Success = 'success',
  Started = 'started',
  PartiallyCompleted = 'Partially completed',
}

export enum DeploymentStepConclusion {
  InProgress = 'in_progress',
  Success = 'success',
  Skipped = 'skipped',
  Failure = 'failure',
  Queued = 'queued',
}

export enum EnvironmentTypes {
  DevEnv = 'Development',
  ProdEnv = 'Production',
}

export const DeploymentStatusMapping = {
  [DeploymentStatus.Active]: 'Active',
  [DeploymentStatus.Suspended]: 'Suspended',
  [DeploymentStatus.NotDeployed]: 'Not Deployed',
  [DeploymentStatus.Error]: 'Error',
  [DeploymentStatus.InProgress]: 'In-progress',
};

export interface EnvDeploymentStatus {
  name: string;
  deploymentStatus: string;
  invokeUrl?: string;
  cron?: string;
  cronTimezone?: string;
  revisionId?: string;
  releaseId?: string;
  versionId?: string;
  commitId?: string;
  envId?: string;
  critical?: boolean;
  buildId?: string;
  apiId?: string;
}

export interface EnvTemplate {
  id: string;
  created_at: string;
  env_name: string;
  region: string;
  cluster_id: string;
  choreo_env: 'private_dp' | string;
  external_apim_env_name: string;
  internal_apim_env_name: string;
  sandbox_apim_env_name: string;
  critical: boolean;
  dns_prefix: string;
}

export interface DevOpsEnvironment {
  ID: string;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  metadata: Record<string, string>;
  name: string;
  organization_id: string;
  project_id: string;
  description: string;
  namespace: string;
  namespaces: null;
  environment_clusters: null;
  critical: boolean;
  app_selector: Record<string, string> | null;
  choreo_env: string | 'dev' | 'prod' | 'private_dp';
}

export interface EnvironmentDetails
  extends Omit<DevOpsEnvironment, 'environment_clusters' | 'namespaces'> {
  environment_clusters: EnvironmentCluster[];
  namespaces: Array<EnvNamespace>;
}

export interface DetailedEnvironment
  extends Omit<DevOpsEnvironment, 'environment_clusters'> {
  environment_clusters: EnvironmentCluster[];
  external_apim_env_name: string;
  organization_id: string;
  project_id: string;
  web_apps_vhost: string;
}

export interface EnvNamespace {
  ID: string;
  metadata: null;
  CreatedAt: string;
  UpdatedAt: string;
  organization_id: string;
  project_id: string;
  name: string;
  environment_id: string;
  environment: null;
  annotations: null;
}

export interface EnvironmentCluster {
  ID: string;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  metadata: {
    system_namespace_create: true;
  };
  cluster: null;
  cluster_id: string;
  environment_id: string;
  environment: null;
  namespace: string;
  mode: 'PRIMARY';
  config_override: null;
  secret_override: null;
}

export interface DeploymentPipeline {
  id: string;
  created_at: string;
  organization_uuid: string;
  promotion_order: Map<string, string[]>;
  name: string;
  is_default: boolean;
}

export interface DeploymentPipelineWiseEnvironments {
  deploymentPipeline: DeploymentPipeline;
  environmentTemplates: EnvTemplate[];
}

export interface DeployedComponent {
  id: string;
  name: string;
  componentType: string;
}

export interface ProjectDeployedComponents {
  projectId: string;
  projectName: string;
  deployedComponents: DeployedComponent[];
}

export interface EnvDeletionEligibility {
  templateId: string;
  envName: string;
  deletable: boolean;
  isClusterActive: boolean;
  deployedComponentsDetails: ProjectDeployedComponents[];
}
